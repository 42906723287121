import React, { useState, useEffect } from "react";
import "../DailyTurn/DailyTurn.css";
import ModalCharge from "./ModalCharge";
import ModalProfile from "./ModalProfile";
import Spinner from "../utils/Spinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Clientele = () => {
  const [dailyTurn, setDailyTurn] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const [searchValue, setSearchValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const token = localStorage.getItem("receptionToken");

  const fetchDailyTurn = async (url) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDailyTurn(data.customerList.data);
      setCurrentPage(data.customerList.current_page);
      setNextPageUrl(data.customerList.next_page_url);
      setPrevPageUrl(data.customerList.prev_page_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDailyTurn(`${process.env.REACT_APP_URL}/customer/get-customer-list`);
  }, []);

  const handleNextPage = () => {
    if (nextPageUrl) {
      fetchDailyTurn(nextPageUrl);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      fetchDailyTurn(prevPageUrl);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/customer/search`,
        {
          search: searchValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setDailyTurn(response.data.result);
      setSearchPerformed(true);

    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitToDone = async (userId, completedTreatment) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL}/customer/completion-treatment`,
        {
          user_id: userId,
          completed_treatment: completedTreatment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      toast.success(data.success, { autoClose: 15000 });

      
      setDailyTurn((prevDailyTurn) =>
        prevDailyTurn.map((user) =>
          user.user_id === userId
            ? { ...user, completion_treatment: completedTreatment }
            : user
        )
      );
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-8">
            <p className="dailyTitle">لیست مراجعین</p>
          </div>
          <div className="col-md-4 text-left">
            <Link to={'/dashboard/old-clientele'}><button className="btnDaily">افزودن مراجع قدیمی</button></Link>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container heightTableUser">
          <input
            className="searchUser"
            type="text"
            placeholder="جستجو در لیست مراجعین"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />

          <table id="customers">
          <thead>
              <tr>
                <th>نام مراجعه کننده </th>
                <th></th>
                <th className="text-center">تکمیل دوره درمان</th>
                <th></th>
              </tr>
            </thead>
            {dailyTurn.length > 0 ? (
              dailyTurn.map((user) => (
                <tr key={user.user_id}>
                  <td className="name-pay">{user.first_name} {user.last_name}</td>
                  <td>
                    <ModalCharge id={user.user_id} fullName={user.first_name + " " + user.last_name} />
                  </td>
                  {user.completion_treatment !== null ? (
                    <td className="text-center">
                      <input
                        type="checkbox"
                        className="heightTdCheckBox"
                        id={`exampleCheck${user.user_id}`}
                        checked={user.completion_treatment === "completed"}
                        onChange={() => {
                          const completedTreatment = user.completion_treatment === "completed" ? "not completed" : "completed";
                          handleSubmitToDone(user.user_id, completedTreatment);
                        }}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>
                    <ModalProfile
                      id={user.user_id}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">نتیجه ای یافت نشد.</td>
              </tr>
            )}


          </table>

        </div>
      )}
      {!searchPerformed && (
        <div className="pagination-container mt-2">
          {prevPageUrl && (
            <button className="btn btn-primary ml-2" onClick={handlePrevPage}>
              قبلی
            </button>
          )}
          {nextPageUrl && (
            <button className="btn btn-primary" onClick={handleNextPage}>
              بعدی
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Clientele;
