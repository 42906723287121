import './sidebar.scss';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import dashboardIcon from "./../../../assets/images/sidebar/dashboard-icon.svg";
import assetsIcon from "./../../../assets/images/sidebar/assets-icon.svg";
import calenderIcon from "./../../../assets/images/sidebar/calender-icon.svg";
import ModalResetOprator from './ModalResetOprator';
import axios from 'axios';
import ModalLogout from './ModalLogout';

export const Sidebar = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(0);
  const [operatorList, setOperatorList] = useState([]);
  const token = localStorage.getItem('receptionToken');
  const [isLoading, setIsLoading] = useState(false);

  const listItems = useMemo(
    () => [
      {
        path: '/dashboard/daily-turn',
        name: 'نوبت های روز',
        icon: dashboardIcon,
      },
      {
        path: '/dashboard/clientele',
        name: 'لیست مراجعین',
        icon: assetsIcon,
      },
      {
        path: '/dashboard/reserve-customer',
        name: 'رزرو نوبت',
        icon: calenderIcon,
      },
    ],
    []
  );

  const assignActiveItem = (itemId) => {
    setActiveItem(itemId);
  };

  useEffect(() => {
    const savedActiveItem = localStorage.getItem('activeItem');
    if (savedActiveItem) {
      setActiveItem(parseInt(savedActiveItem));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('activeItem', activeItem.toString());
  }, [activeItem]);

  useEffect(() => {
    const currentIndex = listItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentIndex !== -1) {
      setActiveItem(currentIndex);
    }
  }, [location.pathname, listItems]);

  const fetchOperatorList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}/operator/get-list-hour-work`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOperatorList(data.operartors);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleLogoutOperator = () => {
    fetchOperatorList();
  };

  return (
    <>
      <ModalResetOprator
        operatorList={operatorList}
        isLoading={isLoading}
        onLogoutOperator={handleLogoutOperator}
      />
      <ModalLogout />
      <div className='sidebar-container position-relative'>
        <div className="logo-container row mb-5">
          {/* ... */}
        </div>

        <div className="top-list-container">
          <ul className='ps-1'>
            {listItems.map((item, i) => (
              <Link to={item.path} key={i}>
                <li onClick={() => assignActiveItem(i)} className={activeItem === i ? 'active' : ''}>
                  <img src={item.icon} alt="icon" /><span>{item.name}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>

        <div className="bottom-list-container position-absolute r-15 mb-4">
          <button data-toggle="modal" data-target="#operator" type="button" class="buttonLogoutOperator" onClick={fetchOperatorList}>ثبت ورود - خروج اپراتور</button>
          <div className='container mt-5'>
            <div className='d-flex'>
              <img src={require("../../../assets/images/dashboard/images/logout.png")} className='picAvatar' />
              <h3 className='logoutText mr-3' data-toggle="modal" data-target="#logout">خروج از حساب کاربری</h3>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

