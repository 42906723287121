import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ModalCancel = ({ popC, id, status, fullName}) => {
  const token = localStorage.getItem('receptionToken');
  const [backToWallet, setBackToWallet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitCancel = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/appointment/cancel-appointment-by-secretary`,
        {
          apponitment_id: id,
          back_to_wallet: backToWallet.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.success, { autoClose: 15000 });
      document.getElementById(`${popC + id}`).click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };

  const handleCheckboxChange = (e) => {
    setBackToWallet(e.target.checked);
  };

  return (
    <div>
      {status === 'pending' || status === 'confirmed' ? (
        <div className="cancelPay" data-toggle="modal" data-target={`#${popC}${id}`}>
          لغو نوبت
        </div>
      ) : (
        <div className="pay-costumer-disabled">لغو نوبت</div>
      )}

      <div className="modal fade" id={`${popC}${id}`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">لغو نوبت</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <label htmlFor="recipient-name" className="col-form-label">
                  آیا از لغو نوبت <span className="font-weight-bold">{fullName}</span> مطمئن هستید؟
                </label>
                <div className="form-check mt-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label mr-4" htmlFor="exampleCheck1">
                    بازگرداندن ویعه به مراجع
                  </label>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-danger" onClick={handleSubmitCancel} disabled={isLoading}
                                >
                                    {isLoading ? "در حال انجام ..." : "لغو نوبت"}</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                بازگشت
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCancel;