import React, { useState } from 'react'
import DatePicker, {
    DateObject,
    getAllDatesInRange,
} from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const OldClientele = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [telNumber, setTelNumber] = useState('');
    const [codeMeli, setCodeMeli] = useState('');
    const [address, setAddress] = useState('');
    const [disease, setDisease] = useState('');
    const [historyDrugUse, setHistoryDrugUse] = useState('');
    const [doctor, setDoctor] = useState('');
    const [countSession, setCountSession] = useState('');
    const [isChargingPeriod, setIsChargingPeriod] = useState('false');

    const [isDrugEnabled, setDrugEnabled] = useState(false);
    const [isHaveDisease, setIsHaveDisease] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [date, setDate] = useState();

    const token = localStorage.getItem('receptionToken');

    const handleDrugRadioChange = (event) => {
        setDrugEnabled(event.target.value === 'true');
    };

    const handleDiseaseRadioChange = (event) => {
        setIsHaveDisease(event.target.value === 'true');
    };

    const handleCheckboxChange = (e) => {
        setIsChargingPeriod(e.target.checked ? 'true' : 'false');
    };

    const persianToEnglishDigits = (str) => {
        const persianDigits = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
        const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        let convertedStr = str;
        persianDigits.forEach((digit, index) => {
            convertedStr = convertedStr.replace(digit, englishDigits[index]);
        });

        return convertedStr;
    };

    const handleSubmitOldClientele = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const englishDate = persianToEnglishDigits(date?.format());
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL}/customer/add-old-customers`,
                {
                    firstname,
                    lastname,
                    mobile,
                    tel_number: telNumber,
                    code_meli: codeMeli,
                    address,
                    disease,
                    history_drug_use: historyDrugUse,
                    doctor,
                    count_session: countSession,
                    is_charging_period: isChargingPeriod,
                    last_appointment_date: englishDate
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(data.success, { autoClose: 15000 });

            setTimeout(() => {
                setIsLoading(false);
                navigate("/dashboard/clientele");
            }, 3000);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.error, { autoClose: 15000 });
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-around">
      
                <div className="col-7 mb-3">
            <div className="mt-3 mb-3 text-left item-back-pointer" onClick={() => navigate(-1)}><img src={require("../../assets/images/user-pages/arrow-right.png")} alt="message" className="ml-2" />بازگشت</div>
                    <h3 className="h-pay">افزودن مراجع قدیمی</h3>
                    <div className="container container-old-c p-4">
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="inputFirstname">نام</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputFirstname"
                                    placeholder="نام"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="inputLastname">نام و خانوادگی</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputLastname"
                                    placeholder="نام و خانوادگی"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="codeMeli">کد ملی</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="codeMeli"
                                    placeholder="کد ملی"
                                    value={codeMeli}
                                    onChange={(e) => setCodeMeli(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6">
                                <label htmlFor="inputMobile">شماره موبایل</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputMobile"
                                    placeholder="شماره موبایل"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="inputTel">شماره ثابت</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputTel"
                                    placeholder="شماره ثابت"
                                    value={telNumber}
                                    onChange={(e) => setTelNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <label htmlFor="inputAddressLine1">آدرس</label>
                                <textarea
                                    rows={2}
                                    type="text"
                                    className="form-control"
                                    id="inputAddressLine1"
                                    placeholder="آدرس"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                        </div>

                        <hr />

                        <div className="form-group row">
                            <div className="col-sm-12">
                                <label htmlFor="inputDoctor">پزشک معالج</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputDoctor"
                                    placeholder="پزشک معالج"
                                    value={doctor}
                                    onChange={(e) => setDoctor(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label htmlFor="inputDoctor">سابقه مصرف دارو</label>
                            <div className="radio">
                                <label className="radio-inline ml-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="drugRadio"
                                        id="drugRadioTrue"
                                        value="false"
                                        checked={!isDrugEnabled}
                                        onChange={handleDrugRadioChange}
                                    />{' '}
                                    <div className="mr-4">ندارد</div>
                                </label>
                                <label className="radio-inline ml-3">
                                    <input
                                        type="radio"
                                        className="form-check-input ml-2 mr-2"
                                        name="drugRadio"
                                        id="drugRadioFalse"
                                        value="true"
                                        checked={isDrugEnabled}
                                        onChange={handleDrugRadioChange}
                                    />{' '}
                                    <div className="mr-4">  دارد</div>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputDrug"
                                    placeholder="نام دارو مصرفی"
                                    value={historyDrugUse}
                                    onChange={(e) => setHistoryDrugUse(e.target.value)}
                                    disabled={!isDrugEnabled}
                                />
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label htmlFor="inputDoctor">بیماری یا بارداری</label>
                            <div className="radio">
                                <label className="radio-inline ml-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="diseaseRadio"
                                        id="diseasegRadioTrue"
                                        value="false"
                                        checked={!isHaveDisease}
                                        onChange={handleDiseaseRadioChange}
                                    />{' '}
                                    <div className="mr-4">ندارد</div>
                                </label>
                                <label className="radio-inline ml-3">
                                    <input
                                        type="radio"
                                        className="form-check-input ml-2 mr-2"
                                        name="diseaseRadio"
                                        id="diseaseRadioFalse"
                                        value="true"
                                        checked={isHaveDisease}
                                        onChange={handleDiseaseRadioChange}
                                    />{' '}
                                    <div className="mr-4">  دارد</div>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputDisease"
                                    placeholder="نوع بیماری"
                                    value={disease}
                                    onChange={(e) => setDisease(e.target.value)}
                                    disabled={!isHaveDisease}
                                />
                            </div>
                        </div>
                        <hr />

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="inputMobile">تاریخ آخرین جلسه</label>
                                <DatePicker
                                    className="go"
                                    fixMainPosition
                                    calendar={persian}
                                    locale={persian_fa}
                                    value={date}
                                    onChange={(dateObjects) => {
                                        setDate(dateObjects);
                                    }}
                                    plugins={[<DatePanel eachDaysInRange />]}
                                />
                            </div>
                            <div class="col-sm-6">
                                <label for="inputTel">تعداد جلسات</label>
                                <input type="text" class="form-control" id="inputTel" placeholder="تعداد جلسات" value={countSession}
                                    onChange={(e) => setCountSession(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-check mt-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label mr-4" htmlFor="exampleCheck1">
                                وارد دوره شارژ شده است
                            </label>
                        </div>

                        <div class="form-group row">

                            <div class="col-sm-12">
                                <button type="button" class="btn btn-primary m-4 float-left" onClick={handleSubmitOldClientele} disabled={isLoading}
                                >
                                    {isLoading ? "در حال انجام ..." : "تایید اطلاعات"}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OldClientele