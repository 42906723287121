import React from 'react'
import ModalPayment from './ModalPayment';
import ModalCancel from './ModalCancel';
import { NavLink } from "react-router-dom";

const DailyTable = ({ dailyTurn, searchResult, inSearching, handleSearchUser }) => {

  const toggleTable = () => {
    var x = document.getElementById("customers2");
    if (x.style.display === "none") {
      x.style.display = "table";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <>
      <div className="container heightTableUser">
        <input
          onChange={handleSearchUser}
          className="searchUser"
          type="search"
          placeholder="جستجو در نوبت های روز"
        />
        {inSearching ? (
          <table id="customers">
            <thead>
              <tr>
                <th>نام مراجعه </th>
                <th>ساعت مراجعه</th>
                <th>ناحیه</th>
                <th>وضعیت نوبت</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {searchResult.length < 0
              ? "مراجع کننده ای با این نام وجود ندارد"
              : searchResult.map((user) => {
                return (
                  <tr key={user.apponitment_id}>
                    <td className="name-pay">{user.first_name} {user.last_name}</td>
                    <td>{user.time}</td>
                    <td>{user.area_names}</td>
                    <td>
                      {user.status === 'pending' ?
                        <div className="pay-costumer-pending">در انتظار پرداخت</div>
                        : user.status === 'cancelled' ?
                          <div className="pay-costumer-cancel">لغو شده</div>
                          :
                          <div className="pay-costumer">پرداخت شده</div>
                      }
                    </td>
                    <td className="link-pay">
                      {user.status === 'confirmed' ? "" : (
                        <NavLink to={`/dashboard/payment/${user.apponitment_id}`} state={{ id: user.apponitment_id }}>
                          پرداخت
                        </NavLink>
                      )}
                    </td>
                    <td><ModalCancel popC="cr" id={user.apponitment_id} status={user.status} fullName={`${user.first_name} ${user.last_name}`} /></td>
                  </tr>
                );
              })}
          </table>
        ) : (
          <table id="customers">
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام مراجعه </th>
                <th>ساعت مراجعه</th>
                <th>ناحیه</th>
                <th>وضعیت نوبت</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {dailyTurn?.map((user, i) => {
              return (
                <tr key={user.apponitment_id}>
                  <td>{i + 1}</td>
                  <td className="name-pay">{user.first_name} {user.last_name}</td>
                  <td>{user.time}</td>
                  <td>{user.area_names}</td>
                  <td>
                    {user.status === 'pending' ?
                      <div className="pay-costumer-pending">در انتظار پرداخت</div>
                      : user.status === 'cancelled' ?
                        <div className="pay-costumer-cancel">لغو شده</div>
                        :
                        <div className="pay-costumer">پرداخت شده</div>
                    }
                  </td>
                  <td className="link-pay">
                    {user.status === 'confirmed' ? "" : (
                      <NavLink to={`/dashboard/payment/${user.apponitment_id}`} state={{ id: user.apponitment_id }}>
                        پرداخت
                      </NavLink>
                    )}
                  </td>
                  <td><ModalCancel popC="cr" id={user.apponitment_id} status={user.status} fullName={`${user.first_name} ${user.last_name}`} /></td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    </>
  );
};

export default DailyTable