
import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import ModalBetweenTurn from './ModalBetweenTurn';

const BetweenTurn = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const navigate = useNavigate();

    const token = localStorage.getItem("receptionToken");


    const [userAreas, setUserAreas] = useState([]);
    const [allAreas, setAllAreas] = useState([]);

    const [userOperator, setUserOperator] = useState(null);
    const [allOperators, setOperators] = useState([]);

    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        const fetchAllAreas = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL}/area/get-list`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setAllAreas(data);
            } catch (error) {
                toast.error(error.response.data.error, { autoClose: 15000 });
            }
        };

        const fetchOperators = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL}/operator/get-list`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setOperators(data);
            } catch (error) {
                toast.error(error.response.data.error, { autoClose: 15000 });
            }
        };

        fetchAllAreas();
        fetchOperators()
    }, []);


    useEffect(() => {
        setFilteredResults(filteredData);
    }, [searchTerm]);

    const handleSearch = debounce((value) => {
        if (value.trim().length > 2) {
            setSearchTerm(value);
        }
    }, 300);

    const handleItemClick = (userId) => {
        const item = filteredResults.find((item) => item.user_id === userId); // Find the selected item
        setSelectedItem(item); // Store the selected item in the state
        setSelectedUserId(userId);
        setFilteredResults([]);
    };

    const handleSetSelectedUser = (userId) => {
        const item = filteredResults.find((item) => item.user_id === userId);
        setSelectedItem(item);
        setSelectedUserId(userId);
        setFilteredResults([]);
    };

    const filteredData = filteredResults.filter((item) => {
        const fullName = item.first_name + ' ' + item.last_name;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });




    const handleSelectAreas = (e) => {
        const selectedOptions = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        );
        setUserAreas((prevAreas) => {
            const updatedAreas = [...prevAreas];
            selectedOptions.forEach((selectedOption) => {
                const selectedArea = allAreas.find(
                    (area) => area.area_id.toString() === selectedOption
                );
                if (
                    selectedArea &&
                    !updatedAreas.some((area) => area.area_id === selectedArea.area_id)
                ) {
                    updatedAreas.push(selectedArea);
                }
            });
            return updatedAreas;
        });
    };

    const handleRemoveArea = (areaId) => {
        setUserAreas((prevAreas) =>
            prevAreas.filter((area) => area.area_id !== areaId)
        );
    };

    const handleConfirmAreas = () => {
        const selectedAreaNames = userAreas.map((area) => area.name).join(",");
        document.getElementById("areas").value = selectedAreaNames;
        document.getElementById("Areas").click();
    };

    const handleOperatorSelect = (event) => {
        const selectedOperatorId = event.target.value;
        setUserOperator(selectedOperatorId);
    };


    useEffect(() => {
        const fetchSearchData = async () => {
            try {
                if (searchTerm.trim() === '') {
                    setFilteredResults([]);
                    return;
                }

                const { data } = await axios.post(`${process.env.REACT_APP_URL}/customer/search`, {
                    search: searchTerm,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFilteredResults(data.result);
            } catch (error) {
                toast.error(error.response.data.error, { autoClose: 15000 });
                console.error(error);
            }
        };

        fetchSearchData();
    }, [searchTerm]);

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL}/appointment/add-by-secretary`,
                {
                    user_id: selectedItem?.user_id || selectedUserId,
                    operator_id: userOperator,
                    area: userAreas
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(data.success, { autoClose: 15000 });
            if (data) {
                setIsLoading(false)
                navigate(`/dashboard/payment/${data.apponitment_id}`);
            }
        } catch (error) {
            setIsLoading(false);

            toast.error(error.response.data.error, { autoClose: 15000 });
        }
    };

    return (
        <>

            <div className="modal fade" id="Areas" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content width-areas-pop">
                        <div className="modal-header">
                            <h5 className="modal-title">تغییر نواحی</h5>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body body-areas-pop">
                            <select
                                className="select-time-cancel p-2"
                                onChange={handleSelectAreas}
                            >
                                <option value="">انتخاب کنید</option>
                                {allAreas.map((area) => (
                                    <option key={area.area_id} value={area.area_id}>
                                        {area.name}
                                    </option>
                                ))}
                            </select>

                            <div className="mt-3">
                                <div>نواحی انتخاب شده</div>
                                <div className="d-flex">
                                    {userAreas.map((area) => (
                                        <div key={area.area_id}>
                                            <button
                                                className="select-area-cancel-items mt-2"
                                                style={{ marginRight: "5px" }}
                                                onClick={() => handleRemoveArea(area.area_id)}
                                            >
                                                {area.name}{" "}
                                                <img
                                                    src={require("../../assets/images/dashboard/images/close-circle.png")}
                                                    className="select-time-cancel-items-pic"
                                                    alt="Close"
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleConfirmAreas}
                            >
                                تایید نواحی
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                لغو تغییرات
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <form className="form-body-pad">
            <div className="mt-3 mb-3 text-left item-back-pointer" onClick={() => navigate(-1)}><img src={require("../../assets/images/user-pages/arrow-right.png")} alt="message" className="ml-2" />بازگشت</div>
                <h3 className="h-pay">مراجع بین نوبت</h3>
                <input
                    type="text"
                    id="search"
                    name="search"
                    className="search-c mb-3"
                    placeholder="نام و نام خانوادگی مراجعه کننده را وارد کنید"
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <ModalBetweenTurn setSelectedUser={handleSetSelectedUser} />


                {
                    searchTerm.trim() !== '' && (
                        <ul className="ul-search-c">
                            {filteredResults.map((item) => (
                                <li
                                    key={item.user_id}
                                    onClick={() => handleItemClick(item.user_id)}
                                    className="li-search-c"
                                >
                                    {item.first_name} {item.last_name} - {item.mobile}
                                </li>
                            ))}
                        </ul>
                    )
                }

                {selectedItem || selectedUserId !== null ? (
                    <>
                        {selectedItem ? (
                            <div className="modal-body">
                                <label htmlFor="name" className="input-label">
                                    نام
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="input-element-p"
                                    name="name"
                                    value={`${selectedItem.first_name} ${selectedItem.last_name}`}
                                    disabled
                                />
                            </div>
                        ) : selectedUserId !== null ? (
                            <div className="modal-body">
                                <label htmlFor="name" className="input-label">
                                    نام
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="input-element-p"
                                    name="name"
                                    value={`اطلاعات کد کاربری ${selectedUserId} ثبت گردید`}
                                    disabled
                                />
                            </div>
                        ) : null}

                        <div className="input-group mt-2">
                            <label htmlFor="areas" className="input-label w-100">
                                <div className="d-flex">
                                    <div>ناحیه لیزر</div>
                                    <div
                                        className="pl-4 mr-auto font-weight-bold"
                                        data-toggle="modal"
                                        data-target="#Areas"
                                    >
                                        افزودن یا تغییر نواحی
                                    </div>
                                </div>
                            </label>
                            <input
                                type="text"
                                id="areas"
                                className="input-element-p"
                                name="areas"
                                value={userAreas.length > 0 ? userAreas.map(area => area.name).join(",") : "ناحیه ای انتخاب نشده است"}
                                style={{ color: userAreas.length === 0 ? '#cdcdcd' : 'inherit' }}
                                disabled
                            />
                        </div>

                        <div className="input-group mt-2">
                            <select
                                className="select-opt p-2"
                                onChange={handleOperatorSelect}
                            >
                                <option value="">انتخاب اوپراتور</option>
                                {allOperators.map((operator) => (
                                    <option key={operator.operator_id} value={operator.operator_id}>
                                        {operator.first_name} {operator.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <hr />

                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-block btn-primary" onClick={handleSubmit} disabled={isLoading}
                            >
                                {isLoading ? "در حال انتقال به صفحه پرداخت ..." : "تکمیل پرداخت و ادامه"}</button>
                        </div>
                    </>
                ) : null}

            </form>
        </>
    );
};

export default BetweenTurn;
