import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../utils/Spinner";

const PagePayment = () => {

  const { id } = useParams();

  const token = localStorage.getItem("receptionToken");
  const navigate = useNavigate();

  const [reservePaymentInfo, setReservePaymentInfo] = useState()
  const [payable, setPayable] = useState()
  const [debt, setDebt] = useState()
  const [code, setCode] = useState()
  const [apponitmentId, setApponitmentId] = useState()
  const [customerId, setCustomerId] = useState()
  const [mobileCustomer, setMobileCustomer] = useState()

  const [userAreas, setUserAreas] = useState([])
  const [allAreas, setAllAreas] = useState([])

  const [paymentType, setPaymentType] = useState([])
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [paymentItems, setPaymentItems] = useState([
    { amount: "", payment_type_id: "null", status: "null" },
  ]);

  const addPaymentItem = () => {
    setPaymentItems((prevItems) => [
      ...prevItems,
      { amount: "", payment_type_id: null, status: null },
    ]);
  };

  const removePaymentItem = (index) => {
    setPaymentItems((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const fetchPaymentDetail = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/dashboard/reserve-info`,
        {
          apponitment_id: id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDebt(data.debt)
      setPayable(data.payable)
      setReservePaymentInfo(data.reserveInfo);
      setCustomerId(data.reserveInfo.customer_id);
      setMobileCustomer(data.reserveInfo.customer_mobile);
      setApponitmentId(data.reserveInfo.apponitment_id)
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
      setIsLoading(false);
    }
  };

  useEffect(() => {

    const fetchUserAreas = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/area/get-area-for-appointment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUserAreas(data)
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    };

    const fetchMultiPayStatus = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/payment/get-appointment-payments/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPaymentItems(data.getPayments)
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    };

    const fetchPaymentType = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/payment/get-payment-type`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPaymentType(data)
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    };

    const fetchAllAreas = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/area/get-list-for-customer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAllAreas(data)
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    };

    fetchPaymentDetail();
    fetchUserAreas();
    fetchAllAreas();
    fetchPaymentType();
    fetchMultiPayStatus()
  }, []);

  const handleDiscountCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL}/discount/use-discount`, {
        apponitment_id: apponitmentId,
        code
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

      toast.success(data.message, { autoClose: 15000 });
      fetchPaymentDetail();
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };

  const handlePaymentTypeSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL}/payment/complete-by-secretary`, {
        apponitment_id: id,
        payment_type_id: selectedPaymentType
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

      toast.success(data.success, { autoClose: 15000 });



      if (data) {
        navigate("/dashboard/daily-turn");
      }
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };

  const handleRemoveArea = (areaId) => {
    setUserAreas((prevAreas) => prevAreas.filter((area) => area.area_id !== areaId));
  };

  const handlePaymentTypeChange = (event) => {
    setSelectedPaymentType(event.target.value);
  };



  const handleSelectAreas = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setUserAreas((prevAreas) => {
      const updatedAreas = [...prevAreas];
      selectedOptions.forEach((selectedOption) => {
        const selectedArea = allAreas.find((area) => area.area_id.toString() === selectedOption);
        if (selectedArea && !updatedAreas.some((area) => area.area_id === selectedArea.area_id)) {
          updatedAreas.push(selectedArea);
        }
      });
      return updatedAreas;
    });
  };

  const submitAreaChange = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/area/change-area-by-secretary`,
        {
          apponitment_id: id,
          area: userAreas
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );



      toast.success(data.success, { autoClose: 15000 });
      document.getElementById("N" + id).click();
      fetchPaymentDetail();

    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };


  const submitPaymentMultiWay = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/payment/add-appointment-payments`,
        {
          apponitment_id: apponitmentId,
          payment_id: apponitmentId,
          payment: paymentItems
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.success, { autoClose: 15000 });
      document.getElementById("Payment" + id).click();
      fetchPaymentDetail();

    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };


  const submitPaymentDebt = async () => {
    const confirmed = window.confirm("آیا مطمئن هستید که میخواهید بدهی را پرداخت کنید؟");

    if (confirmed) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/wallet/completed-payment`,
          {
            customer_id: customerId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data.success, { autoClose: 15000 });
        fetchPaymentDetail();
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    }
  };


  return (
    <>

      <div className="mt-3 mb-3 text-left item-back-pointer" onClick={() => navigate(-1)}><img src={require("../../assets/images/user-pages/arrow-right.png")} alt="message" className="ml-2" />بازگشت</div>

      <div class="modal fade" id={"N" + id} aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content width-areas-pop">
            <div class="modal-header">
              <h5 class="modal-title">تغییر نواحی</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body body-areas-pop">
              <select className="select-time-cancel p-2" onChange={handleSelectAreas}>
                <option value="">انتخاب کنید</option>
                {allAreas.map((area) => (
                  <option key={area.area_id} value={area.area_id}>
                    {area.name}
                  </option>
                ))}
              </select>

              <div className="mt-3">
                <div>نواحی انتخاب شده</div>
                <div className="d-flex">
                  {userAreas.map((area) => (
                    <div key={area.area_id}>
                      <button
                        className="select-area-cancel-items mt-2"
                        style={{ marginRight: '5px' }}
                        onClick={() => handleRemoveArea(area.area_id)}
                      >
                        {area.name} <img src={require("../../assets/images/dashboard/images/close-circle.png")} className="select-time-cancel-items-pic" alt="Close" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            <div class="modal-footer justify-content-between">

              <button type="button" class="btn btn-primary" onClick={submitAreaChange}>تایید نواحی</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">لغو تغییرات</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id={"Payment" + id} aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">پرداخت به چند روش</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body body-areas-pop">
              <div class="alert alert-warning">
                میتوانید با کلیک بر روی <strong>+ افزودن روش پرداخت</strong>  به تعداد دلخواه روش های مختلف پرداخت مشتری را اضافه کنید.
              </div>
              {paymentItems.map((item, index) => (
                <div key={index} className="multi-way-pay mt-2">
                  <div className="d-flex mb-3">
                    <div>پرداخت {index + 1}</div>
                    {item.status == null && (
                      <div
                        className="mr-auto"
                        onClick={() => removePaymentItem(index)}
                        style={{ cursor: "pointer", color: "red" }}
                      >
                        حذف
                      </div>
                    )}
                  </div>
                  <select
                    className="form-control select-time-cancel p-2"
                    value={item.payment_type_id}
                    onChange={(e) =>
                      setPaymentItems((prevItems) =>
                        prevItems.map((prevItem, i) =>
                          i === index
                            ? { ...prevItem, payment_type_id: e.target.value }
                            : prevItem
                        )
                      )
                    }
                  >
                    <option value="">انتخاب کنید</option>
                    {paymentType.map((item) => (
                      <option key={item.payment_type_id} value={item.payment_type_id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    id="name"
                    className="form-control mt-4"
                    name="name"
                    placeholder="مبلغ به تومان"
                    value={item.amount}
                    onChange={(e) =>
                      setPaymentItems((prevItems) =>
                        prevItems.map((prevItem, i) =>
                          i === index
                            ? { ...prevItem, amount: e.target.value }
                            : prevItem
                        )
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <div className="mt-3 mb-3 mr-3">
              <div onClick={addPaymentItem} style={{ cursor: "pointer" }}>
                + افزودن روش پرداخت
              </div>
            </div>

            <div class="modal-footer justify-content-between">

              <button type="button" class="btn btn-primary" onClick={submitPaymentMultiWay}>تایید تغییرات</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">لغو تغییرات</button>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <form className="form-body-pad">

          {debt > 0 && (
            <div className="payment-notifaction-success-body">
              <div className="payment-notifaction">
                <div className="payment-button-notfication">
                  موجودی کیف پول {debt.toLocaleString()} تومان می باشد .
                  که از مبلغ قابل پرداخت کسر گردید
                </div>
              </div>
            </div>
          )}
          {debt < 0 && (
            <div className="payment-notifaction-red-body">
              <div className="payment-notifaction">
                <div>ابتدا بدهی خود را ({debt.toLocaleString()} تومان) پرداخت کنید.</div>
                <div className="payment-button-notfication" onClick={submitPaymentDebt}>پرداخت بدهی</div>
              </div>
            </div>
          )}

          <div class="modal-body">
            <label htmlFor="name" className="input-label">
              نام
            </label>
            <input
              type="text"
              id="name"
              className="input-element-p"
              name="name"
              value={reservePaymentInfo?.customer_name + " " + reservePaymentInfo?.customer_last_name}
              disabled
            />
          </div>
          <div className="input-group mt-2">
            <label htmlFor="turn_time" className="input-label">
              زمان نوبت
            </label>
            <input
              type="text"
              id="turn_time"
              className="input-element-p"
              name="turn_time"
              value={reservePaymentInfo?.time}
              disabled
            />
          </div>

          <div className="input-group mt-2">
            <label htmlFor="turn_time" className="input-label">
              شماره موبایل
            </label>
            <input
              type="text"
              id="customer_mobile"
              className="input-element-p"
              name="customer_mobile"
              value={reservePaymentInfo?.customer_mobile}
              disabled
            />
          </div>

          <div className="input-group mt-2">
            <label htmlFor="areas" className="input-label w-100">
              <div className="d-flex">
                <div>ناحیه لیزر</div>
                <div className="pl-4 mr-auto" data-toggle="modal" data-target={"#N" + id} style={{ cursor: "pointer", color: "#3866F7", fontSize: "13px" }}>تغییر نواحی</div>
              </div>
            </label>
            <input
              type="text"
              id="areas"
              className="input-element-p"
              name="areas"
              value={reservePaymentInfo?.areas.join(",")}
              disabled
            />
          </div>

          <div className="input-group mt-2 d-flex">
            <label htmlFor="code" className="input-label">
              کد تخفیف
            </label>
            <input
              type="text"
              id="code"
              name="code"
              value={code}
              className="input-element-p w-380"
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-sm btn-dark mr-auto rounded"
              onClick={handleDiscountCodeSubmit}
            >
              اعمال کد تخفیف
            </button>
          </div>



          <div className="mt-4">
            <div className="d-flex mb-3">
              <div>روش پرداخت</div>
              <div className="mr-auto" data-toggle="modal" data-target={"#Payment" + id} style={{ cursor: "pointer", color: "#3866F7", fontSize: "13px" }}>پرداخت به چند روش</div>
            </div>

            {paymentType.map((type) => (
              <label className="radio-inline mr-2" key={type.payment_type_id}>
                <input
                  type="radio"
                  name="optradio"
                  value={type.payment_type_id}
                  onChange={handlePaymentTypeChange}
                />  {type.title}
              </label>
            ))}
          </div>

          <hr />

          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-primary" onClick={handlePaymentTypeSubmit}>
              تکمیل پرداخت
            </button>
            <div>
              <div className="sum-title">
                مبلغ قابل پرداخت
              </div>

              <div className="sum-pay">
                {payable?.toLocaleString()} تومان
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default PagePayment;