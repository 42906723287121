import React, { useState } from 'react'
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const ModalBetweenTurn = ({ setSelectedUser }) => {

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [mobile, setMobile] = useState('');
  const [telNumber, setTelNumber] = useState('');
  const [codeMeli, setCodeMeli] = useState('');
  const [address, setAddress] = useState('');
  const [disease, setDisease] = useState('');
  const [historyDrugUse, setHistoryDrugUse] = useState('');
  const [doctor, setDoctor] = useState('');

  const [isDrugEnabled, setDrugEnabled] = useState(false);
  const [isHaveDisease, setIsHaveDisease] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();


  const token = localStorage.getItem('receptionToken');

  const handleDrugRadioChange = (event) => {
    setDrugEnabled(event.target.value === 'true');
  };

  const handleDiseaseRadioChange = (event) => {
    setIsHaveDisease(event.target.value === 'true');
  };



  const handleSubmitNewClientele = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {

      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/customer/add-by-secretary`,
        {
          firstname,
          lastname,
          mobile,
          tel_number: telNumber,
          code_meli: codeMeli,
          address,
          disease,
          history_drug_use: historyDrugUse,
          doctor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.success, { autoClose: 15000 });

      if (data.user_id) {
        setSelectedUser(data.user_id);
        document.getElementById("gon").click();
        setIsLoading(false);

      }
      // setTimeout(() => {
      //   navigate("/dashboard/clientele");
      // }, 3000);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };
  return (
    <div>
      <div className="payButton mb-3" data-toggle="modal" data-target="#gon">
        + مراجع جدید
      </div>



      <div className="modal fade" id="gon" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content w-600">
            <div className="modal-header">
              <h5 className="modal-title">مراجع جدید</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row justify-content-around">
                  <div className="col-12">
                    <div className="container container-old-c">
                      <div className="form-group row">
                        <div className="col-sm-4">
                          <label htmlFor="inputFirstname">نام</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputFirstname"
                            placeholder="نام"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="inputLastname">نام و خانوادگی</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputLastname"
                            placeholder="نام و خانوادگی"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="codeMeli">کد ملی</label>
                          <input
                            type="text"
                            className="form-control"
                            id="codeMeli"
                            placeholder="کد ملی"
                            value={codeMeli}
                            onChange={(e) => setCodeMeli(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label htmlFor="inputMobile">شماره موبایل</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputMobile"
                            placeholder="شماره موبایل"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label htmlFor="inputTel">شماره ثابت</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputTel"
                            placeholder="شماره ثابت"
                            value={telNumber}
                            onChange={(e) => setTelNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label htmlFor="inputAddressLine1">آدرس</label>
                          <textarea
                            rows={2}
                            type="text"
                            className="form-control"
                            id="inputAddressLine1"
                            placeholder="آدرس"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>

                      <hr />

                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label htmlFor="inputDoctor">پزشک معالج</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputDoctor"
                            placeholder="پزشک معالج"
                            value={doctor}
                            onChange={(e) => setDoctor(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row mt-4">
                        <label htmlFor="inputDoctor">سابقه مصرف دارو</label>
                        <div className="radio">
                          <label className="radio-inline ml-3">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="drugRadio"
                              id="drugRadioTrue"
                              value="false"
                              checked={!isDrugEnabled}
                              onChange={handleDrugRadioChange}
                            />{' '}
                            <div className="mr-4">ندارد</div>
                          </label>
                          <label className="radio-inline ml-3">
                            <input
                              type="radio"
                              className="form-check-input ml-2 mr-2"
                              name="drugRadio"
                              id="drugRadioFalse"
                              value="true"
                              checked={isDrugEnabled}
                              onChange={handleDrugRadioChange}
                            />{' '}
                            <div className="mr-4">  دارد</div>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputDrug"
                            placeholder="نام دارو مصرفی"
                            value={historyDrugUse}
                            onChange={(e) => setHistoryDrugUse(e.target.value)}
                            disabled={!isDrugEnabled}
                          />
                        </div>
                      </div>

                      <div className="form-group row mt-4">
                        <label htmlFor="inputDoctor">بیماری یا بارداری</label>
                        <div className="radio">
                          <label className="radio-inline ml-3">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="diseaseRadio"
                              id="diseasegRadioTrue"
                              value="false"
                              checked={!isHaveDisease}
                              onChange={handleDiseaseRadioChange}
                            />{' '}
                            <div className="mr-4">ندارد</div>
                          </label>
                          <label className="radio-inline ml-3">
                            <input
                              type="radio"
                              className="form-check-input ml-2 mr-2"
                              name="diseaseRadio"
                              id="diseaseRadioFalse"
                              value="true"
                              checked={isHaveDisease}
                              onChange={handleDiseaseRadioChange}
                            />{' '}
                            <div className="mr-4">  دارد</div>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputDisease"
                            placeholder="نوع بیماری"
                            value={disease}
                            onChange={(e) => setDisease(e.target.value)}
                            disabled={!isHaveDisease}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-check mt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          checked
                        />
                        <label className="form-check-label mr-4" htmlFor="exampleCheck1">
                          امضا بصورت فیزیکی از مراجع گرفته شد
                        </label>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-block btn-primary" onClick={handleSubmitNewClientele} disabled={isLoading}
              >
                {isLoading ? "در حال انجام ..." : "تایید اطلاعات و ادامه"}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBetweenTurn;