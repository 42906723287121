import React, { useEffect, useState } from "react";
import "./DailyTurn.css";
import axios from "axios";
import DailyTable from "./DailyTable";
import Spinner from "../utils/Spinner";
import { Link } from "react-router-dom";

const DailyTurn = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [inSearching, setInSearching] = useState(false);
  const [dailyTurn, setDailyTurn] = useState([]);
  const [nextDay, setNextDay] = useState();
  const [previousDay, setPreviousDay] = useState();
  const [today, setToday] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("receptionToken");

  const handleSearchUser = (e) => {
    setInSearching(true);
    const searchQuery = e.target.value.toLowerCase().trim();
    
    if (searchQuery === "") {
      setSearchResult(dailyTurn);
      return;
    }
  
    const searchTerms = searchQuery.split(/\s+/);
    
    const results = dailyTurn.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      
      return searchTerms.every(term => fullName.includes(term));
    });
  
    setSearchResult(results);
  };
  


  const fetchDailyInfo = async (date) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/dashboard/secretary`,
        {
          date: date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDailyTurn(data.appointment);
      setNextDay(data.nextDay);
      setPreviousDay(data.previousDay);
      setToday(data.todayDate);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchDailyInfo("");
  }, []);

  const handlePreviousDayClick = () => {
    fetchDailyInfo(previousDay);
  };

  const handleNextDayClick = () => {
    fetchDailyInfo(nextDay);
  };

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h2>نوبت های روز</h2>
          </div>

          <div>
            <img
              className="imgS pointClick ml-2"
              src={require("../../assets/images/dashboard/images/p-week.png")}
              alt="logo"
              onClick={handlePreviousDayClick}
            />

            {today}
            <img
              className="imgS pointClick mr-2"
              src={require("../../assets/images/dashboard/images/n-week.png")}
              alt="logo"
              onClick={handleNextDayClick}
            />
          </div>
          <div>
            <Link to={'/dashboard/between-clientele'}> <button className="btnDaily" data-toggle="modal">مراجع بین نوبت</button></Link>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <DailyTable
          searchResult={searchResult}
          inSearching={inSearching}
          dailyTurn={dailyTurn}
          handleSearchUser={handleSearchUser}
        />
      )}

    </>
  );
};

export default DailyTurn;
