import React, { useState } from "react";
import "./Entry.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Login = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const togglePassword = (e) => {
    e.preventDefault()
    setPasswordShown(!passwordShown);
  };

  const Login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://lianalaser.com/laser/api/auth/secretary/login",
        {
          user_name: userName,
          password: password,
        },
      );
  
      const data = response.data.success;
      localStorage.setItem("receptionToken", data.token);
      navigate("/dashboard/daily-turn");
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
      setIsLoading(false);
    }
  };
  

  return (

    <>
      <div className="container">

        <div className="d-flex vh-100 overflow-auto">
          <div className="row">
          <h2 className="font-weight-bold pt-5">ورود به عنوان منشی</h2>
          </div>
          <div className="col d-flex justify-content-center align-self-center">
            <div className="boderBody width-login">
              <p className="titleLogin">
                نام کاربری و رمز ورود خود را وارد کنید.
              </p>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="inputEmail4">نام کاربری</label>
                    <input
                      type="text"
                      id="inputEmail4"
                      placeholder="نام کاربری"
                      onChange={e => setUserName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="inputEmail4">رمز ورود</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="inputEmail4"
                      placeholder="رمز ورود"
                      onChange={e => setPassword(e.target.value)}
                    />
                    <button className="test" onClick={togglePassword}></button>
                  </div>
                </div>

                <button type="button" className="buttonLogin" onClick={Login} disabled={isLoading}>
                {isLoading ? "در حال ورود به پنل منشی ..." : "ورود"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;