import "./Dashboard.scss";
import { Outlet } from "react-router-dom";
import { Nav } from "../../components/shared/navbar/Nav";
import { Sidebar } from "../../components/shared/sidebar/Sidebar";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import DailyTurn from "../../components/DailyTurn/DailyTurn";
import Clientele from "../../components/Clientele/Clientele.js";
import PagePayment from "../../components/DailyTurn/PagePayment";
import OldClientele from "../../components/Clientele/OldClientele";
import BetweenTurn from "../../components/DailyTurn/BetweenTurn";
import { useEffect, useState } from "react";
import ReserveCustomer from "../../components/Reserve/ReserveCustomer.js";

export const Dashboard = () => {

  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const receptionToken = localStorage.getItem("receptionToken");
    if (!receptionToken) {
      navigate('/login');
    } else {
      setTokenVerified(true);
    }
  }, []);

  return (
    <>
      {tokenVerified && (
        <div className="container-fluid main-container">
          <div className="row">
            <div className="col-2 col-lg-2 col-sm-1 px-0">
              <Sidebar />
            </div>
            <div className="col-10 col-lg-10 col-sm-11 px-0">
              <Nav />
              <Outlet className="w-100" />
              <div className="container bg-white">
                <Routes>
                  <Route path="/daily-turn" element={<DailyTurn />} />
                  <Route path="/payment/:id" element={<PagePayment />} />
                  <Route path="/clientele" element={<Clientele />} />
                  <Route path="/old-clientele" element={<OldClientele />} />
                  <Route path="/between-clientele" element={<BetweenTurn />} />
                  <Route path="/reserve-customer" element={<ReserveCustomer />} />

                </Routes>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
