import React from "react";
import './Entry.css'
const Entry = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 px-0 d-none d-sm-block">
            <img
              src={require("../../assets/images/user-pages/entry.png")}
              alt="Login image"
              className="w-100 vh-100"
            />
          </div>

          <div className="col-5 align-self-center ml-4 mr-4">
            <h2 className="font-weight-bold marginB100">به سامانه مدیریت کلینیک لیزر <br /> <span className="highlighted-text">اسپندا</span> خوش آمدید</h2>
            <div className="d-flex justify-content-between margintop25">
            <button type="button" class="buttonEntry">ورود به عنوان منشی</button>
            <button type="button" class="buttonEntry">ورود به عنوان مدیر</button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Entry;
