import React, { useState } from 'react'
import './Clientele.css'
import axios from 'axios';
import { toast } from "react-toastify";
import Spinner from "../utils/Spinner";

const ModalProfile = ({ id }) => {
  const [profileUser, setProfileUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("receptionToken");

  const handleProfileSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_URL}/customer/customer-info-for-secretary/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setProfileUser(data.customerInfo)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  }

  const handleSubmitToChargeInProfile = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_URL}/customer/add-to-charge/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.success , { autoClose: 15000 });
      document.getElementById("profile"+id).click();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="float-left" data-toggle="modal" data-target={"#profile" + id} onClick={handleProfileSubmit}>
        <img

          className="imgS pointClick"
          src={require("../../assets/images/dashboard/images/arrow-left.png")}
          alt="logo"
        />
      </div>

      <div class="modal fade" id={"profile" + id} aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">پروفایل مراجع</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <form>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div class="modal-body">

                    <div className="input-group mt-2">
                      <label for="recipient-name" className="input-label">نام و نام خانوادگی</label>
                      <input type="text" className="input-element" placeholder={profileUser.first_name +" "+ profileUser.last_name} disabled />
                    </div>

                    <div className="input-group mt-2">
                      <label for="recipient-name" className="input-label">شماره تماس</label>
                      <input type="text" className="input-element" placeholder={profileUser.mobile} disabled />
                    </div>
                    <div className="input-group mt-2">
                      <label for="recipient-name" className="input-label">تعداد جلسات</label>
                      <input type="text" className="input-element" placeholder={profileUser.count_session} disabled />
                    </div>

                    <div className="input-group mt-2">
                      <label for="recipient-name" className="input-label">آخرین جلسه</label>
                      <input type="text" className="input-element" placeholder={profileUser.date} disabled />
                    </div>

                    <div className="input-group mt-2">
                      <label for="recipient-name" className="input-label">اوپراتور</label>
                      <input type="text" className="input-element" placeholder={profileUser.operator_name} disabled />
                    </div>


                  </div>
                )}
              </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-block btn-primary btn-f-b" onClick={handleSubmitToChargeInProfile}>ورود به شارژ</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalProfile