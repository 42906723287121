import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../../utils/Spinner';

const ModalResetOprator = ({
  operatorList,
  isLoading,
  onLogoutOperator,
}) => {
  const [selectedOperatorId, setSelectedOperatorId] = useState('');

  const token = localStorage.getItem('receptionToken');

  const handleOperatorSelection = (operatorId) => {
    setSelectedOperatorId(operatorId);
  };

  const handleLoginOperator = async (operatorId) => {
    setSelectedOperatorId(operatorId);
    const promptResult = window.confirm('آیا از ثبت ورود اوپراتور مطمئن هستید؟');
    if (promptResult) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/hour-work/add`,
          {
            operator_id: operatorId,
            start_hour: '',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data.success, { autoClose: 15000 });
        document.getElementById('operator').click();
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    }
  };

  const handleLogoutOperator = async (operatorId) => {
    setSelectedOperatorId(operatorId);
    const promptResult = window.confirm('آیا از ثبت خروج اوپراتور مطمئن هستید؟');
    if (promptResult) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/hour-work/end`,
          {
            operator_id: operatorId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(data.success, { autoClose: 15000 });
        document.getElementById('operator').click();
        onLogoutOperator(); // Call the onLogoutOperator function passed as a prop
      } catch (error) {
        toast.error(error.response.data.error, { autoClose: 15000 });
      }
    }
  };

  return (
    <div className='position-absolute'>
      <div className="modal fade submit-shift" id="operator" aria-hidden="true">
        <div className="modal-dialog position-absolute di-edit">
          <div className="modal-content md-edit">
            <div className="modal-header">
              <h5 className="modal-title">ثبت ورود - خروج اوپراتور</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="modal-body">
                <form>
                  <label htmlFor="recipient-name" className="col-form-label">آیا از ثبت ورود اوپراتور مطمئن هستید؟</label>
                  {operatorList?.map((operator) => (
                    <div className='d-flex justify-content-between' key={operator.operator_id}>
                      <div className='d-flex flex-row mt-2'>
                        <label htmlFor={`operator_${operator.operator_id}`} className='mr-1'>{`${operator.first_name} ${operator.last_name}`} {operator.status === "login" ? <span class="badge badge-pill badge-info">در حال فعالیت</span> : ""}</label>
                      </div>

                      <div>
                      {operator.status === "login" ? (
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => handleLogoutOperator(operator.operator_id)}
                        >
                          خروج
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => handleLoginOperator(operator.operator_id)}
                        >
                          ورود
                        </button>
                      )}
                      </div>
                    </div>
                  ))}
                </form>
              </div>
            )}

            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">بازگشت</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalResetOprator;
