import {Route, Routes, BrowserRouter  } from 'react-router-dom';

import { Dashboard } from './pages/Dashboard/Dashboard';
import Entry from './pages/user-pages/Entry';
import Login from './pages/user-pages/Login';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <>
    <BrowserRouter>
    <ToastContainer autoClose={8000} />
      <Routes>
      <Route path="dashboard/*" element={<Dashboard />} />
        {/* <Route path="/" element={<Main />}>
          <Route path="/booking" element={<Booking />}/>
          <Route path="/sell" element={<SellCars />}/>
        </Route> */}

        <Route path="entry" element={<Entry />} />
        <Route path="login" element={<Login />} />

      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
