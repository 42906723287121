import React, { useState } from 'react'
import './Clientele.css'
import axios from 'axios';
import { toast } from "react-toastify";


const ModalCharge = ({ id, fullName }) => {

  const token = localStorage.getItem("receptionToken");
  const [isLoading, setIsLoading] = useState(false);
  const [status45Day, setStatus45Day] = useState("false");

  const handleCheckboxChange = () => {
    setStatus45Day(status45Day === "true" ? "false" : "true");
  };

  const handleSubmitToCharge = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL}/customer/add-to-charge`,
        {
          user_id: id,
          status_45_day: status45Day,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      toast.success(data.success, { autoClose: 15000 });
      document.getElementById("charge" + id).click();
      setIsLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error(error.response.data.error, { autoClose: 15000 });
      setIsLoading(false);
    }
  };


  return (
    <>
      <div className="chargeModalText" data-toggle="modal" data-target={"#charge" + id}>
        ورود به شارژ
      </div>

      <div class="modal fade" id={"charge" + id} aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">ورود به شارژ</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <label for="recipient-name" class="col-form-label">آیا از ثبت ورود به شارژ برای <span className="font-weight-bold">{fullName}</span> مطمئن هستید؟</label>
                <div class="form-check mt-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={status45Day === "true"}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label mr-4" for="exampleCheck1">وضعیت ارسال پیامک ۴۵ روزه</label>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-danger" onClick={handleSubmitToCharge} disabled={isLoading} >
                {isLoading ? "در حال انجام ..." : "ورود به شارژ"} </button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">بازگشت</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalCharge