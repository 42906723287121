import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../../utils/Spinner';

const ModalLogout = () => {

  const token = localStorage.getItem('receptionToken');
  const [isLoading, setIsLoading] = useState(false);

  const logoutSecretary = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}/hour-work/end-time-secretary`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data) {
        setIsLoading(false);
        localStorage.removeItem("receptionToken");
        window.location.href = "/login";
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.error, { autoClose: 15000 });
    }
  };

  return (
    <div className='position-absolute'>
      <div className="modal fade submit-shift" id="logout" aria-hidden="true">
        <div className="modal-dialog position-absolute di-edit">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="modal-content md-edit">
              <div className="modal-header">
                <h5 className="modal-title">خروج از حساب کاربری</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form>
                  <label htmlFor="recipient-name" className="col-form-label">خروج از حساب کاربری به معنی پایان ساعت کاری می باشد، آیا میخواهید از حساب کاربری خارج شوید؟</label>
                </form>
              </div>

              <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-dark" onClick={logoutSecretary}>خروج از حساب کاربری</button>
                <button type="button" className="btn btn-light" data-dismiss="modal">بازگشت</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalLogout;
