import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import './Reserve.css'
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const ReserveCustomer = () => {
    const token = localStorage.getItem("receptionToken");


    const navigate = useNavigate();

    const fetchTimesAndInfo = async (previousDays, nextDays) => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_URL}/booking/get-date-for-customer`,
                {
                    previous_days: previousDays,
                    next_days: nextDays,
                    areas_time: calculateTotalTime()
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAllTimesAndInfo(data.result);
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });
        }
    };

    const handlePreviousClick = () => {
        if (allTimesAndInfo.length > 0) {
            const previousDate = allTimesAndInfo[0].item.date;

            fetchTimesAndInfo(previousDate, '');
        }
    };

    const handleNextClick = () => {
        if (allTimesAndInfo.length > 0) {
            const lastIndex = allTimesAndInfo.length - 1;
            const nextDate = allTimesAndInfo[lastIndex].item.date;

            fetchTimesAndInfo('', nextDate);
        }
    };

    useEffect(() => {

        const fetchAllAreas = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL}/area/get-list-for-customer`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setAllAreas(data);
            } catch (error) {
                toast.error(error.response.data.error, { autoClose: 15000 });
            }
        };

        fetchAllAreas();
    }, [])


    const [currentStep, setCurrentStep] = useState(1);


    const [allAreas, setAllAreas] = useState();
    const [allTimesAndInfo, setAllTimesAndInfo] = useState();

    const totalSteps = 4;

    const [selectedValue, setSelectedValue] = useState('Morning');
    const [showGuide, setShowGuide] = useState(true);



    const [selectedItemData, setSelectedItemData] = useState(null);
    const [selectedTimeId, setSelectedTimeId] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);

    const [buttonText, setButtonText] = useState('ادامه');

    const [selectedTimeDetails, setSelectedTimeDetails] = useState(null);
    const [selectedOperator, setSelectedOperator] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [selectedMorningShiftData, setSelectedMorningShiftData] = useState({
        "morning": [],
        "afternoon": [],
    });

    const [selectedAreas, setSelectedAreas] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [customerId, setCustomerId] = useState(null);
    const [selectedFullName, setSelectedFullName] = useState(null);
    const [selectedMobile, setSelectedMobile] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const handleAreaSelection = (areaId) => {
        const areaExists = selectedAreas.some((area) => area.area_id === areaId);

        if (areaExists) {
            const updatedSelectedAreas = selectedAreas.filter((area) => area.area_id !== areaId);
            setSelectedAreas(updatedSelectedAreas);
        } else {
            const selectedArea = allAreas.find((area) => area.area_id === areaId);
            setSelectedAreas([...selectedAreas, selectedArea]);
        }
    };




    useEffect(() => {
        switch (currentStep) {
            case 1:
                setButtonText('ادامه');
                break;
            case 2:
            case 3:
                setButtonText('ادامه');
                break;
            case 4:
                setButtonText('ثبت اطلاعات');
                break;
            default:
                setButtonText('ادامه');
                break;
        }
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep === 2 && selectedAreas.length === 0) {
            toast.error("بایستی یکی از آیتم های مربوطه را انتخاب کنید.", { autoClose: 5000 });
        }else if (currentStep === 1 && selectedMobile === null) {
            toast.error("ابتدا یک کاربر را انتخاب کنید.", { autoClose: 5000 });
        }
         else if (currentStep === 3 && !selectedTimeId) {
            toast.error("بازه زمانی مراجعه به مطب را انتخاب کنید.", { autoClose: 5000 });
        } else {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };


    const handleToggle = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'Morning' && selectedItemData && selectedItemData.morning) {
            setSelectedMorningShiftData(selectedItemData.morning);
        } else if (event.target.value === 'Night' && selectedItemData && selectedItemData.afternoon) {
            setSelectedMorningShiftData(selectedItemData.afternoon);
        } else {
            setSelectedMorningShiftData([]);
        }
    };

    const handleTimeClick = (item) => {
        setSelectedTimeId(item.booking_schedule_detail_time_id);
        setSelectedTimeDetails({
            start_hour: item.start_hour,
            end_hour: item.end_hour,
        });
        setSelectedOperator(item.operator_name);
    };

    const calculateTotalTime = () => {
        const totalMilliseconds = selectedAreas.reduce((acc, area) => {
            const [hours, minutes, seconds] = area.time.split(':').map(Number);
            const milliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
            return acc + milliseconds;
        }, 0);

        const totalHours = Math.floor(totalMilliseconds / (60 * 60 * 1000));
        const totalMinutes = Math.floor((totalMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
        const totalSeconds = Math.floor((totalMilliseconds % (60 * 1000)) / 1000);

        return `${totalHours.toString().padStart(2, '0')}:${totalMinutes
            .toString()
            .padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
    };

    const calculateTotalPrice = () => {
        const totalPrice = selectedAreas.reduce((acc, area) => acc + Number(area.price), 0);
        return totalPrice.toLocaleString();
    };

    const handleItemClick = (item) => {
        setSelectedValue('Morning')
        setSelectedItemData(item.shifts);

        const date = item.date;

        setSelectedDate(date);
        setSelectedMorningShiftData(item.shifts.morning)

    };



    const handleFormSubmit = async () => {
        switch (currentStep) {
            case 1:
                nextStep();
                break;

            case 2:
                fetchTimesAndInfo();
                nextStep();
                break;

            case 3:
                nextStep();
                break;

            case 4:
                try {
                    setIsLoading(true);
                    const selectedAreaIds = selectedAreas.map((area) => ({ area_id: area.area_id }));
                    const { data } = await axios.post(`${process.env.REACT_APP_URL}/appointment/add-appointment-by-secretary`,
                        {
                            customer_id: customerId,
                            date: selectedDate,
                            booking_schedule_detail_time_id: selectedTimeId,
                            areas: selectedAreaIds

                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    toast.success(data.success, { autoClose: 15000 });
                    setIsLoading(false);
                    if (data) {
                        navigate('/dashboard/daily-turn', { state: data });
                    }
                } catch (error) {
                    toast.error(error.response.data.error, { autoClose: 15000 });
                    setIsLoading(false);
                }
                break;
            default:
                console.error('Invalid step number');
                break;
        }
    };


    useEffect(() => {
        setFilteredResults(filteredData);
    }, [searchTerm]);

    const handleSearch = debounce((value) => {
        if (value.trim().length > 2) {
            setSearchTerm(value);
        }
    }, 300);

    const handleItemClickSearch = (userId) => {
        const item = filteredResults.find((item) => item.user_id === userId);

        setSelectedItem(item);
        setCustomerId(item.customer_id);
        setSelectedFullName(item.first_name + " " + item.last_name);
        setSelectedMobile(item.mobile)
        setFilteredResults([]);
    };

    const filteredData = filteredResults.filter((item) => {
        const fullName = item.first_name + ' ' + item.last_name;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
        const fetchSearchData = async () => {
            try {
                if (searchTerm.trim() === '') {
                    setFilteredResults([]);
                    return;
                }

                const { data } = await axios.post(`${process.env.REACT_APP_URL}/customer/search`, {
                    search: searchTerm,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setFilteredResults(data.result);
            } catch (error) {
                toast.error(error.response.data.error, { autoClose: 15000 });
                console.error(error);
            }
        };

        fetchSearchData();
    }, [searchTerm]);


    const renderStep = (step) => {
        switch (step) {
            case 1:
                return (
                    <div className="container-form container-reserve-dash">
                        <input
                            type="text"
                            id="search"
                            name="search"
                            className="search-c mb-3"
                            placeholder="نام و نام خانوادگی مراجعه کننده را وارد کنید"
                            onChange={(e) => handleSearch(e.target.value)}
                        />


                        {
                            searchTerm.trim() !== '' && (
                                <ul className="ul-search-c">
                                    {filteredResults.map((item) => (
                                        <li
                                            key={item.user_id}
                                            onClick={() => handleItemClickSearch(item.user_id)}
                                            className="li-search-c"
                                        >
                                            {item.first_name} {item.last_name} - {item.mobile}
                                        </li>
                                    ))}
                                </ul>
                            )
                        }

                        {selectedFullName !== null && (
                            <div className="alert alert-success">
                                کاربر <strong>{selectedFullName}</strong> با شماره تماس <strong>{selectedMobile}</strong> انتخاب شد.
                            </div>
                        )}

                    </div>
                );

            case 2:
                return (
                    <>
                        <div className="container-form container-reserve-dash">
                            <p className="f-14">نواحی مورد نظر خود را انتخاب بکنید</p>
                            {allAreas &&
                                allAreas.map((area) => (
                                    <div className="item-body" key={area.area_id}>
                                        <div className="container">
                                            <div className="body-title-form">
                                                <input
                                                    className="checkHeight"
                                                    type="checkbox"
                                                    onChange={() => handleAreaSelection(area.area_id)}
                                                    checked={selectedAreas.some((selectedArea) => selectedArea.area_id === area.area_id)}
                                                />
                                                {area.name}
                                            </div>
                                            <div className="price-body">{area.price.toLocaleString()} تومان</div>
                                        </div>
                                    </div>
                                ))}

                            {/* <form onSubmit={handleSubmit} className="step-form">
                            <h2>Step 1: Personal Information</h2>
                            <label>
                                Name:
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </label>
                            <label>
                                Email:
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </label>
                            <button type="submit" onClick={nextStep}>
                                Next
                            </button>
                        </form> */}
                        </div>

                        <div className="info-reserve-step1">
                            <p className="f-14">اطلاعات نوبت</p>
                            <div className="container">
                                <div className="step1-1">مدت زمان جلسه:</div>
                                <div className="step1-2">{calculateTotalTime()}</div>
                            </div>

                            <div className="container">
                                <div className="step1-1">نواحی انتخاب شده:</div>
                                <div className="step1-2">{selectedAreas.map((area) => (
                                    <>{area.name},</>
                                ))}</div>
                            </div>

                            <div className="container">
                                <div className="step1-1">مبلغ کل</div>
                                <div className="step1-2">{calculateTotalPrice()} تومان</div>
                            </div>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="container-form mt-4">
                            <form>
                                <div className="form-body">
                                    <div className="des-notice-step2 f-14 mb-3">روز و بازه زمانی مورد نظر خود را انتخاب کنید</div>
                                    <div className="horizontal-scroll-container">
                                        <div className="item" onClick={handlePreviousClick}>
                                            <div className="month-step2">قبلی</div>
                                        </div>
                                        {allTimesAndInfo?.map(({ item }) => {
                                            const { date, shifts } = item;
                                            const morningShiftsCount = shifts.morning ? shifts.morning.reduce((count, shift) => count + (shift.operator.times ? shift.operator.times.length : 0), 0) : 0;
                                            const afternoonShiftsCount = shifts.afternoon ? shifts.afternoon.reduce((count, shift) => count + (shift.operator.times ? shift.operator.times.length : 0), 0) : 0;
                                            const shiftsCount = morningShiftsCount + afternoonShiftsCount;

                                            return (
                                                <div className={`item ${selectedDate === date ? 'selected' : ''}`} key={date} onClick={() => handleItemClick(item)}>
                                                    <div className={`month-step2 ${selectedDate === date ? 'white-text' : ''}`}>{item.farsi_date}</div>
                                                    <div className={`day-step2 ${selectedDate === date ? 'white-text' : ''}`}>{item.farsi_day}</div>
                                                    <div className={`range-step2 ${selectedDate === date ? 'white-text' : ''}`}>{shiftsCount} بازه</div>
                                                </div>
                                            );
                                        })}



                                        <div className="item">
                                            <div className="month-step2" onClick={handleNextClick}>بعدی</div>
                                        </div>
                                    </div>

                                    <div className="toggle-switch-container mt-4">
                                        <label
                                            className={`toggle-switch-option ${selectedValue === 'Morning' ? 'active' : ''
                                                }`}
                                        >
                                            <input
                                                type="radio"
                                                value="Morning"
                                                checked={selectedValue === 'Morning'}
                                                onChange={handleToggle}
                                            />
                                            شیفت صبح
                                        </label>
                                        <label
                                            className={`toggle-switch-option ${selectedValue === 'Night' ? 'active' : ''
                                                }`}
                                        >
                                            <input
                                                type="radio"
                                                value="Night"
                                                checked={selectedValue === 'Night'}
                                                onChange={handleToggle}
                                            />
                                            شیفت عصر
                                        </label>

                                    </div>

                                    <div className="mb-3">
                                        {selectedValue === 'Morning' && selectedMorningShiftData && selectedMorningShiftData.length > 0 ? (
                                            <div>
                                                {selectedMorningShiftData.map((operatorData) => (
                                                    <div key={operatorData.operator.operator_id}>
                                                        <div className="operator-name">اوپراتور: {operatorData.operator.name}</div>
                                                        <div className="container-times">
                                                            {operatorData.operator.times && operatorData.operator.times.map((time) => (
                                                                <div
                                                                    className={`item-times ${selectedTimeId === time.booking_schedule_detail_time_id ? 'selected' : ''} ${time.status !== 'free' ? 'unavailable-shift' : ''}`}
                                                                    key={time.booking_schedule_detail_time_id}
                                                                    onClick={() => time.status === 'free' ? handleTimeClick(time) : null}
                                                                >
                                                                    {time.start_hour} - {time.end_hour}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        {selectedValue === 'Night' && selectedMorningShiftData && selectedMorningShiftData.length > 0 ? (
                                            <div>
                                                {selectedMorningShiftData.map((operatorData) => (
                                                    <div key={operatorData.operator.operator_id}>
                                                        <div className="operator-name">اوپراتور: {operatorData.operator.name}</div>
                                                        <div className="container-times">
                                                            {operatorData.operator.times && operatorData.operator.times.map((time) => (
                                                                <div
                                                                    className={`item-times ${selectedTimeId === time.booking_schedule_detail_time_id ? 'selected' : ''} ${time.status !== 'free' ? 'unavailable-shift' : ''}`}
                                                                    key={time.booking_schedule_detail_time_id}
                                                                    onClick={() => time.status === 'free' ? handleTimeClick(time) : null}
                                                                >
                                                                    {time.start_hour} - {time.end_hour}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>

                                </div>

                            </form>
                        </div>
                    </>
                );


            case 4:
                return (
                    <>

                        <div className="container-form">
                            <div>
                                <div className="form-body">
                                    <div className="home-handle mb-3"><div className="icon-titr-purple"></div> اطلاعات نوبت</div>

                                    <div className="info-user-border-one">
                                        <div className="info-user-step4">
                                            <div className="info-title">نام</div>
                                            <div className="info-value">{selectedFullName || null}</div>
                                        </div>

                                        <div className="info-user-step4">
                                            <div className="info-title">موبایل</div>
                                            <div className="info-value">{selectedMobile || null}</div>
                                        </div>
                                        <div className="info-user-step4">
                                            <div className="info-title">روز و تاریخ</div>
                                            <div className="info-value">{selectedDate}</div>
                                        </div>

                                        <div className="info-user-step4">
                                            <div className="info-title">بازه زمانی</div>
                                            <div className="info-value">{selectedTimeDetails?.start_hour} - {selectedTimeDetails?.end_hour}</div>
                                        </div>

                                        <div className="info-user-step4">
                                            <div className="info-title">اوپراتور</div>
                                            <div className="info-value">{selectedOperator}</div>
                                        </div>

                                        <div className="info-user-step4">
                                            <div className="info-title">ناحیه</div>
                                            <div className="info-value info-value-height">
                                                {selectedAreas.map((area) => (
                                                    <div key={area.area_id}>{area.name}</div>
                                                ))}
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    </>
                );

            default:
                return null;
        }
    };

    return (
        <div dir="rtl" className='mx-width'>

            <div className="navbar-reserve step-icons">
                <div className="container">
                    {Array.from({ length: totalSteps }, (_, index) => (
                        <div
                            key={index}
                            className={`step-icon ${currentStep === index + 1 ? 'active' : ''}`}
                        >
                            {currentStep > index + 1 ? (
                                <FaCheck />
                            ) : (
                                <span>{index + 1}</span>
                            )}
                        </div>
                    ))}
                </div>

            </div>

            <div className="reserve-header-handle">
                {currentStep !== 1 && (
                    <div className="container">
                        <div className="pr-header-handle" onClick={prevStep}>مرحله قبل</div>
                    </div>
                )}

            </div>

            <div className="step-content">
                {/* Render the current step */}
                {renderStep(currentStep)}
            </div>

            <div className="btn-footer btn-sub-reserve">
                <button type="submit" className="btn-continue-step" onClick={handleFormSubmit} disabled={isLoading}>
                    {isLoading ? "در حال انجام ..." : buttonText}
                </button>
            </div>
        </div>

    );
};

export default ReserveCustomer;